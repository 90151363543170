<template>
    <transition name="fade slide-sidebar">
        <div class="list" v-show="isOpen">
            <div class="list__header">
                <div class="list__info">
                    <div class="list__name">
                        Quay List
                    </div>
                    <div class="list__amount">
                        {{ displayedQuays.length }}
                    </div>
                </div>
                <a
                    class="list__close"
                    :class="{ 'list__close_only-small': !isFromCustomSidebar }"
                    href="javascript://"
                    @click="close"
                >
                    <mf-icon icon-name="icon-close" width="18" height="18" />
                </a>
            </div>
            <div class="list__fields">
                <div class="fields__line">
                    <mf-input v-model="searchString" placeholder="Search quay" is-search />
                </div>
                <div class="fields__line">
                    <mf-button size="x-small" icon-last @click="reverseOrder">
                        Reverse
                        <mf-icon
                            slot="icon"
                            icon-name="icon-down"
                            width="12"
                            height="12"
                            class="field__icon"
                            :class="{ field__icon_reverse: isASCOrder }"
                        />
                    </mf-button>
                    <mf-dropdown without-icon :open-from-right="false">
                        <div class="fields__item" slot="link">
                            <mf-icon class="fields__icon" icon-name="icon-sort" />
                            <div class="fields__text">Order By</div>
                        </div>
                        <div class="fields__order">
                            <template v-for="(orderByOption, index) in orderByOptions">
                                <mf-radio
                                    class="fields__order-item"
                                    :checked="orderBy === orderByOption.value"
                                    @change="changeOrder(orderByOption.value)"
                                    :key="`radio-${orderByOption.value}`"
                                >
                                    {{ orderByOption.name }}
                                </mf-radio>
                                <mf-divider
                                    :key="`divider-${orderByOption.value}`"
                                    v-if="index !== orderByOptions.length - 1"
                                />
                            </template>
                        </div>
                    </mf-dropdown>
                    <div class="fields__item" @click="toggleFavorites" :class="{ fields__item_active: isFavorites }">
                        <mf-icon class="fields__icon" icon-name="icon-check" />
                        <div class="fields__text">Only selected</div>
                    </div>
                    <div class="fields__item" @click="toggleFilterModal">
                        <mf-icon class="fields__icon" icon-name="icon-filter" />
                        <div class="fields__text">Filter</div>
                    </div>
                </div>
            </div>
            <div class="list__values">
                <template v-for="(quay, index) in displayedQuays">
                    <mf-divider :key="`divider-${index}-${quay.URN}`" />
                    <quay-item
                        :quay="quay"
                        :key="`quay-${index}-${quay.URN}`"
                        :is-selected="selectedQuayId === quay.URN"
                    />
                </template>
                <mf-divider />
            </div>
            <filter-quays-modal :is-open="isFilterModalShown" @close="toggleFilterModal" />
        </div>
    </transition>
</template>

<script>
    import QuayItem from './QuayItem';
    import { mapGetters } from 'vuex';
    import FilterQuaysModal from '@/modals/FilterQuaysModal';

    export default {
        components: { FilterQuaysModal, QuayItem },
        props: {
            isFromCustomSidebar: {
                type: Boolean,
                default: false,
            },
        },
        mounted() {
            this.$store.dispatch('getQuays');
        },
        computed: {
            ...mapGetters({
                quays: 'quays',
                isOpen: 'sidebarValue',
                selectedQuayId: 'selectedQuayId',
                favouriteQuays: 'favouriteQuays',
            }),
            displayedQuays() {
                return this.sortedQuays.slice().filter(value => {
                    return (
                        (this.isFavorites ? this.isFavoriteQuay(value.URN) : true) &&
                        value.name.toLowerCase().match(this.searchString.toLowerCase())
                    );
                });
            },
            sortedQuays() {
                return this.quays.slice().sort(this.sortQuays);
            },
        },
        data() {
            return {
                isFilterModalShown: false,
                isASCOrder: false,
                isFavorites: false,
                orderBy: 'name',
                orderByOptions: [{ name: 'Name', value: 'name' }],
                searchString: '',
            };
        },
        methods: {
            close() {
                this.$store.commit('setSidebarValue', false);
            },
            toggleFilterModal() {
                this.isFilterModalShown = !this.isFilterModalShown;
            },
            toggleFavorites() {
                this.isFavorites = !this.isFavorites;
            },
            changeOrder(value) {
                this.orderBy = value;
            },
            reverseOrder() {
                this.isASCOrder = !this.isASCOrder;
            },
            sortQuays(first, second) {
                let result = 0;
                if (this.orderBy === 'name') {
                    result = first.name.localeCompare(second.name);
                }
                return this.isASCOrder ? result : -result;
            },
            isFavoriteQuay(URN) {
                return this.favouriteQuays.includes(URN);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .list {
        padding: $space-32 $space-16;
        opacity: 1;
    }

    .list__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: $font-24;
        line-height: $font-32;
    }

    .list__info {
        display: flex;
    }

    .list__name {
        font-weight: bold;
        color: $white;
    }

    .list__amount {
        padding-left: $space-12;
        color: $marine-300;
    }

    .list__button {
        margin-left: $space-16;
        @include media($lg) {
            margin-left: auto;
        }
    }

    .list__close {
        color: $marine-400;
        margin-left: auto;
    }

    .list__close_only-small {
        @include media($lg) {
            display: none;
        }
    }

    .fields__line {
        margin: $space-20 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .fields__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $white;
        font-size: $font-12;
        line-height: $font-14;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    .fields__item_active {
        color: $blue-300;
    }

    .field__icon {
        transition: transform 0.2s ease;
    }

    .field__icon_reverse {
        transform: rotate(180deg);
    }

    .fields__order {
        border: 1px solid $marine-500;
        box-sizing: border-box;
        border-radius: 6px;
    }

    .fields__order-item {
        padding: $space-14 $space-16;
    }

    .fields__icon {
        margin-bottom: $space-8;
    }

    .list__values {
        margin-left: -$space-16;
        margin-right: -$space-16;
    }

    @import '@/assets/sass/transitions/fade';
    @import '@/assets/sass/transitions/slide-sidebar';
</style>
