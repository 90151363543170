<template>
    <mf-modal v-if="isOpen" @close="close">
        <div slot="header">
            Filter Options
        </div>
        <custom-lists type="quay" :options="options" />
        <div slot="footer">
            <div class="filter__buttons">
                <mf-button color="blue" @click="apply">Apply</mf-button>
            </div>
        </div>
    </mf-modal>
</template>

<script>
    import CustomLists from '@/modals/filter-modal-components/CustomLists.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'FilterQuaysModal',
        components: { CustomLists },
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            ...mapGetters({
                options: 'customQuayLists',
            }),
        },
        methods: {
            close() {
                this.$emit('close');
            },
            apply() {
                this.$store.dispatch('setQuaysFromCustomLists', this.options);
                this.close();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .filter__buttons {
        display: flex;
        justify-content: flex-end;
    }
</style>
