<template>
    <div class="quay-item" :class="{ 'quay-item_active': isSelected }" @click="selectQuay">
        <div class="quay-item__info">
            <div class="quay-item__name">
                {{ quay.name }}
            </div>
            <div class="quay-item__short-name">Short name:: {{ quay.shortName }}</div>
        </div>
        <mf-checkbox :checked="isFavorite" @change="toggleFavorite" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'QuayItem',
        props: {
            quay: {
                type: Object,
                required: true,
            },
            isSelected: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapGetters(['favouriteQuays', 'selectedQuayId']),
            isFavorite() {
                return this.favouriteQuays.includes(this.quay.URN);
            },
        },
        methods: {
            selectQuay() {
                if (this.selectedQuayId !== this.quay.URN) {
                    this.$store.commit('setSelectedQuay', this.quay);
                }
            },
            toggleFavorite() {
                this.$store.commit('toggleFavouriteQuay', this.quay.URN);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .quay-item {
        padding: $space-16 0 $space-16 $space-16;
        display: flex;
        border-right: 3px solid transparent;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
    }

    .quay-item_active {
        background: $marine-600;
        border-right: 3px solid $blue-500;
    }

    .quay-item__name {
        word-break: break-word;
        font-weight: 600;
        font-size: $font-18;
        line-height: $font-20;
        color: $white;
    }

    .quay-item__short-name {
        margin-top: 8px;
        font-size: $font-14;
        line-height: $font-18;
        color: $marine-300;
    }
</style>
